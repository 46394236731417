<template>
  <div>
    <!-- 基本信息 -->
    <div class="pad-container">
      <p class="c-3">{{ addFlag ? "添加核销商户" : "编辑核销商户" }}</p>
      <el-form class="mt-24" label-width="100px" :model="verify_merchants_form" :rules="rules"
        ref="verify_merchants_form">
        <el-form-item :label="addFlag ? '添加商户' : '核销商户'" prop="merchantId">
          <el-select placeholder="请选择商户" class="form-input" filterable clearable
            v-model="verify_merchants_form.merchantId">
            <el-option v-for="(item, index) in merchantList" :key="index" :label="item.merchantName"
              :value="item.merchantId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input class="form-input" placeholder="请输入手机号" clearable
            v-model="verify_merchants_form.phone" :maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="核销员工" prop="name">
          <el-input class="form-input" placeholder="请输入核销员工" v-model="verify_merchants_form.name">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="flx-row ali-c js-fe mt-50">
      <el-button plain class="form-button" @click="$router.go(-1)">取消</el-button>
      <el-button class="form-button" type="primary" @click="submit">确定</el-button>
    </div>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      verify_merchants_form: {
        name: "",
        phone: "",
        merchantId: ""
      },
      storeList: [],
      merchantList: [],
      rules: {
        name: [
          { required: true, message: "核销员工不能为空", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: "号码格式有误",
            trigger: "blur"
          }
        ],
        merchantId: [
          { required: true, message: "商户不能为空", trigger: "blur" }
        ]
      },
      addFlag: true,
      rowData: {},
      id: ""
    };
  },
  mounted() {
    this.getMerchantsList();
    let query = this.$route.query;
    this.addFlag = query.flag;
    this.id = query.id;
    if (query.flag == false || query.flag == "false") {
      this.getStaffData(query.id);
    }
  },
  methods: {
    //获取商户列表
    getMerchantsList() {
      let data = {
        pageSize: 0,
        pageNum: 1
      };
      request({
        url: "/customer-service/lrMerchant/queryMerchantByShop",
        method: "post",
        data
      }).then(res => {
        this.merchantList = res.data.body.list;
      });
    },
    getStaffData(id) {
      request({
        url: `/customer-service/merchantAccount/queryMerchantAccountDetails?id=${id}`,
        method: "get"
      }).then(res => {
        let resData = res.data.body;
        this.verify_merchants_form.name = resData.name;
        this.verify_merchants_form.merchantId = resData.merchantId;
        this.verify_merchants_form.phone = resData.phone;
      });
    },
    //传参处理
    handleParams() {
      let params = this.$route.params;
      if (params.rowData || sessionStorage.getItem("ROW_DATA")) {
        this.rowData =
          params.rowData || JSON.parse(sessionStorage.getItem("ROW_DATA"));
        if (params.rowData) {
          sessionStorage.setItem("ROW_DATA", JSON.stringify(params.rowData));
          sessionStorage.setItem("ADD_FLAG", params.flag);
        }
        this.verify_merchants_form.phone = this.rowData.phone;
        this.verify_merchants_form.name = this.rowData.name;
        this.id = this.rowData.id;
        if (String(this.rowData.flag) == "false") this.addFlag = false;
      }
    },
    submit() {
      this.$refs["verify_merchants_form"].validate(valid => {
        if (!valid) {
          this.$message({
            message: "表单信息不完整（有误）",
            type: "warning"
          });
          return;
        }
        this.submitForm();
      });
    },
    submitForm() {
      let data = {};
      if (String(this.addFlag) == "true") {
        data = {
          url: "/customer-service/merchantAccount/addMerchantAccount",
          method: "post",
          data: this.verify_merchants_form
        };
      } else {
        let dataObj = this.verify_merchants_form;
        dataObj.id = this.id;
        data = {
          url: "/customer-service/merchantAccount/updateMerchantAccount",
          method: "post",
          data: dataObj
        };
      }
      request(data).then(res => {
        if (res.data.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success"
          });
        }
        this.$router.go(-1);
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
